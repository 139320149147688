import { ViewportScroller } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable()
export class ScrollService {
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private zone: NgZone,
  ) {}

  // https://garage.sekrab.com/posts/anchor-scrolling-in-angular

  scrollToAnchor$(options?: { yOffset?: number; xOffset?: number }): Observable<any> {
    return this.router.events.pipe(
      filter((event) => event instanceof Scroll),
      map((event) => event as Scroll),
      tap((scrollEvent) => {
        if (!scrollEvent.anchor) return;

        this.zone.runOutsideAngular(() => {
          setTimeout(() => {
            this.zone.run(() => {
              this.viewportScroller.setOffset([options?.xOffset ?? 0, options?.yOffset ?? 0]);
              this.viewportScroller.scrollToAnchor(scrollEvent.anchor ?? '');
            });
          }, 1000);
        });
      }),
    );
  }
}
